export default
{
  id: "",	
  fields: {},
};

export function extendedText(field, extendedField) { // Use to overcome 256-byte ShortText limit
  const value = field;
  const extendedValue = extendedField && extendedField.content && extendedField.content.length && extendedField.content[0].content && extendedField.content[0].content.length && extendedField.content[0].content[0].value;
  if (extendedValue) { // If RichText field exists and has a value, use it
    return extendedValue;
  } else { // Otherwise try the ShortText value
    if (value) { // If ShortText field exists and has a value, use it
      return value;
    } else { // Otherwise return an empty string
      return "";
    }
  }
}
