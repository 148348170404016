///////////////////////////////////////////////////////////
// File        : 404.jsx
// Description : 

// Imports : 

import React from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";

import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./404.scss";

// Function Definition
function NotFound(props) {
  // console.info("404.render");
  return (
    <div className="NotFound">
      <h1>Oops!</h1>
      <div>We can’t seem to find the page you’re looking for.</div>
    </div>
  );
}

// Interface
NotFound.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
NotFound.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default NotFound;

