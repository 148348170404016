import React from "react"
import { Helmet } from "react-helmet"

import NotFound from "~/Components/Pages/404/404";

export default function Page() {
  return (
    <>
      <Helmet htmlAttributes={{lang: "en",}}>
      </Helmet>
      <NotFound />
    </>
  );
}
